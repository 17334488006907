import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import Membership from 'src/components/membership/membership';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const MembershipPage = () => {
  return (
    <Layout>
      <SEO title="Membership" />
      <Router basepath="/memberships">
        <MembershipRoute path="/:membershipId" />
      </Router>
    </Layout>
  );
};

interface MembershipRouteProps extends RouteComponentProps {
  membershipId?: string;
}

const MembershipRoute: React.FC<MembershipRouteProps> = ({ membershipId }) => {
  return (
    <PrivateRoute>
      <Membership membershipId={membershipId} />
    </PrivateRoute>
  );
};

export default MembershipPage;
